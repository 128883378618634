require("@rails/ujs").start();
require("channels");

import "jquery/src/jquery";
import "jquery-chained/jquery.chained";
import "bootstrap/dist/js/bootstrap";
import "../src/material/material";
import "../src/material/ripples";
import "../src/archive"

$(document.body).on('cocoon:after-insert', function () { $.material.init() });
